<template>
  <main class="document container">
    <BreadcrumbsComponent :links="links" />
    <h1 class="document-title">Публичная оферта</h1>
    <div class="document-subtitle">
      <EditorJSComponent
        :text="JSON.parse(info.offer)"
        v-if="info.offer"
        :endpoint="$store.state._env.MEDIA_ENDPOINT"
      />
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import INFO from "@/graphql/queries/Info.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "public-offer",
  components: { EditorJSComponent, BreadcrumbsComponent },
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: INFO,
      })
      .then(({ data }) => {
        store.state.info = data.feedback;
      });
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
  },
  data() {
    return {
      links: [
        {
          children: {
            title: "Публичная оферта",
          },
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: "АИСТ | Публичная оферта",
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: "",
        },
        {
          vmid: "description",
          name: "description",
          content: "АИСТ | Публичная оферта",
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: "АИСТ | Публичная оферта",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: "АИСТ | Публичная оферта",
        },
      ],
    };
  },
};
</script>

<style lang="stylus">
.document {
  padding 140px 0 160px 0
  display flex
  flex-direction column
  gap 60px
  min-height: 100vh;
  +below(1330px) {
    padding 30px 15px
    padding-top: 140px;
    gap 20px
  }

  &-title {
    color var(--pink)
    //line-height 72px
    max-width 709px
    +below(375px) {
      font-size 1.5em
    }
  }

  &-subtitle {
    font-size 1.25em
    line-height 26px
    max-width 980px
  }
}
</style>
